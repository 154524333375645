.app-item
	min-width: 150px
	min-height: 150px

	.icon-box
		height: 64px
		width: 64px
		font-size: 64px
		line-height: 100%
		text-align: center
