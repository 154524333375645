@charset "utf-8"

html, body, .app
	margin: 0px
	padding: 0px
	height: 100vh

.container
	flex-grow: 1
	margin: 0 auto
	position: relative
	width: auto

	@media screen and (max-width: 1215px) and (min-width: 1024px)
		max-width: 960px
	@media screen and (max-width: 1407px) and (min-width: 1216px)
		max-width: 1152px
	@media screen and (min-width: 1408px)
		max-width: 1344px
	@media screen and (max-width: 1214px)
		padding-left: 24px
		padding-right: 24px

.pt-2
	padding-top: 0.5rem

.pt-5
	padding-top: 1.5rem

.pb-5
	padding-bottom: 1.5rem
